export function parseFormData(result:any){
    const data:any = {}; 
    for(const key of Object.keys(result)){
        data[key] = result[key].map((item:any) => {
            if(key === 'phone_codes'){
                item.value = item.code.toString();
                item.label = item.iso + " +" + item.code;
            }else{
                item.value = item.code;
                item.label = item.name;
            }
            
            delete(item.code);
            delete(item.name);
            return item;
        })
    }
    return data;
}