import React, { FunctionComponent } from 'react';

import './Icon.scss';

type IconProps = {
    name: string,
}

const Icon: FunctionComponent<IconProps> = ({name}) => 
  
  <svg className="adm-icon" width={24} height={24}><use href={name}></use></svg>

export default Icon;



