import React, { FunctionComponent } from 'react';

import './Loading.scss';

const Loading: FunctionComponent = () =>
    <div></div>

export default Loading;



