import { FunctionComponent } from 'react';
import Footermenu from 'components/footer/Footermenu';
import './Footer.scss';


const Footer: FunctionComponent = () => 
    <footer>
        <div className="inner">
            <Footermenu></Footermenu>
        </div>
    </footer>

export default Footer;