import { FunctionComponent } from 'react';

import Button from 'components/button/Button';
import { withTranslation, WithTranslation } from 'react-i18next';

import './BackToTopButton.scss';

const BackToTopButton: FunctionComponent<WithTranslation> = ({t, i18n}) => {

    const clickBackToTopHandler = () => {
        window.scrollTo(0, 0);
    };

    return (
        <div className="back-to-top">
            <Button cssStyle="back-to-top" icon="chevron-up" label={t("common:button.to.top")} onClick={() => clickBackToTopHandler()} ></Button>
        </div>
    );
};

export default withTranslation(['common'])(BackToTopButton);