/**
 *  ------------------------------------
 *  Imports
 *  ------------------------------------
 */
import { FunctionComponent, useState, useEffect } from 'react';
import { NavLink, useLocation, NavLinkProps } from "react-router-dom";
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import configData from 'app-config.json';

import './Navbar.scss';

/**
 *  ------------------------------------
 *  Navbar Component
 *  ------------------------------------
 */
type NavbarItemProps = {
    disabled?: boolean
}

const NavbarItem: FunctionComponent<NavbarItemProps & NavLinkProps> = ({ children, disabled, to, ...props }) => {

    const clickHandler = () => {
        window.dispatchEvent(new Event("closeMenu"));
    }

    return (
        <>               
            {disabled ? (
                <li className="step">  
                    <>{children}</>
                </li> 
            ) : (
                <li>    
                    <NavLink to={to} className={({ isActive }) => isActive ? 'step is-active' : 'step is-valid' } {...props} onClick={clickHandler}>
                        {children}
                    </NavLink>
                </li>    
            )}
        </>
    );
}


 const Navbar: FunctionComponent<WithTranslation> = ({ t }) => {

    const [step, setStep] = useState(1);
    const location = useLocation();

    useEffect(() => {
        const pathParts = location.pathname.split("/");
        if(pathParts.length === 3){
            setStep(configData.REGISTRATION_STEPS_ORDER.indexOf(pathParts[2]) + 1);
        }else{
            setStep(1);
        }
    }, [location.pathname])

    return (
        <aside>
            <nav aria-label='Seiten Navigation'>
                <ol className="steps-list"> 
                    <NavbarItem to="/registration/insurancecard" disabled={step < 1}>
                        <Trans i18nKey="common:navbar.step1.label"></Trans>
                    </NavbarItem>     
                    <NavbarItem to="/registration/personal_details" disabled={step < 2}>
                        <Trans i18nKey="common:navbar.step2.label"></Trans>
                    </NavbarItem>    
                    <NavbarItem to="/registration/employer" disabled={step < 3}>
                        <Trans i18nKey="common:navbar.step5.label"></Trans>
                    </NavbarItem>   
                    <NavbarItem to="/registration/medical_appointment" disabled={step < 4}>
                        <Trans i18nKey="common:navbar.step6.label"></Trans>
                    </NavbarItem>   
                    <NavbarItem to="/registration/insurance_relationship" disabled={step < 5}>
                        <Trans i18nKey="common:navbar.step7.label"></Trans>
                    </NavbarItem>   
                    <NavbarItem to="/registration/checkout" disabled={step < 6}>
                        <Trans i18nKey="common:navbar.step8.label"></Trans>
                    </NavbarItem>             
                </ol>
            </nav>
        </aside>
    );
}

/**
 *  ------------------------------------
 *  Exports
 *  ------------------------------------
 */
export {NavbarItem};
export default withTranslation(['common'])(Navbar);
