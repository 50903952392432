/**
 *  ------------------------------------
 *  Imports
 *  ------------------------------------
 */
import { I18nextProvider, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';
import { setLocale } from 'yup';
import configData from 'app-config.json';
import { setDatePickerDefaultLocale } from './datepicker/i18n_datepicker';

import { setMomentDefaultLocale } from './moment/i18n_moment';

/**
 *  ------------------------------------
 *  Component
 *  ------------------------------------
 */
i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // lazy loading for translations
    .use(backend)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        ns: ['validation', 'common'],
        fallbackLng: 'de',

        // prevent 404 resources not found
        supportedLngs: configData.SUPPORTED_LANGUAGES,
        // Do not set to prevent 404 resources not found
        // nonExplicitSupportedLngs: true,
    }, () => {
        setValidationMessagesLocale();
    });


const setValidationMessagesLocale = () => {
    setLocale({
        mixed: {
            required: i18n.t("validation:error.mixed.required"),
        },
        string: {
            length: i18n.t("validation:error.string.length"),
            //min: '${path} muss mindestens ${min} Zeichen lang sein',
            max: i18n.t("validation:error.string.max"),
            email: i18n.t("validation:error.custom.email"),
            //url: '${path} muss eine gültige URL sein',
            //trim: '${path} darf keine Leerzeichen am Anfang oder Ende enthalten',
            //lowercase: '${path} darf nur Kleinschreibung enthalten',
            //uppercase: '${path} darf nur Großschreibung enthalten'
        }
    });
}

/**
 *  ------------------------------------
 *  Exports
 *  ------------------------------------
 */
export const changeLanguage = async (language: string) => {
    await i18n.changeLanguage(language);
    // set locales
    setDatePickerDefaultLocale(language);
    setMomentDefaultLocale(language);
    setValidationMessagesLocale();
};

export const setHtmlLanguage = (language: string) => {
    document.documentElement.lang = language;
};

export const setMetaTags = (language: string) => {
    let locale:string = "";
    switch(language){
        case "de": 
            locale = 'de_DE'; 
            break;
        case "en": 
            locale = 'en_GB'; 
            break;
        case "fr": 
            locale = 'fr_FR'; 
            break;
        case "it":
            locale = 'it_IT';
            break;
    }
    document.title = i18n.t("common:meta.title");
    (document.querySelector('meta[name="title"]') as Element).setAttribute("content", i18n.t("common:meta.title"));
    (document.querySelector('meta[name="description"]') as Element).setAttribute("content", i18n.t("common:meta.description"));

    (document.querySelector('meta[property="og:title"]') as Element).setAttribute("content", i18n.t("common:meta.og.title"));
    (document.querySelector('meta[property="og:locale"]') as Element).setAttribute("content", locale);
    (document.querySelector('meta[property="og:url"]') as Element).setAttribute("content", "https://checkin.inselgruppe.ch/"+language);

    (document.querySelector('meta[name="twitter:title"]') as Element).setAttribute("content", i18n.t("common:meta.twitter.title"));

    (document.querySelector('meta[name="dcterms.rights"]') as Element).setAttribute("content", i18n.t("common:meta.dcterms.rights"));
    (document.querySelector('meta[name="dc.language"]') as Element).setAttribute("content", language);
};

export { I18nextProvider, i18n };
