import { FunctionComponent } from 'react';
import {withTranslation, WithTranslation} from "react-i18next";

const Footermenu: FunctionComponent<WithTranslation> = ({t}) => {

    const clickHandler = () => {
        window.dispatchEvent(new Event("closeMenu"));
    }

    return (
        <>
            <nav aria-label='Footer Navigation'>
                <a href={t("common:footer.impressum.link")} target="_blank" rel="noreferrer" onClick={clickHandler}>{t("common:footer.impressum.label")}</a>
                <a href={t("common:footer.disclaimer.link")} target="_blank" rel="noreferrer" onClick={clickHandler}>{t("common:footer.disclaimer.label")}</a>
                <a href={t("common:footer.privacy.link")} target="_blank" rel="noreferrer" onClick={clickHandler}>{t("common:footer.privacy.label")}</a>
            </nav>
            <p>&copy; 2022 Insel Gruppe AG</p>
        </>
    )
}

export default withTranslation(['common'])(Footermenu);