import React, { FunctionComponent, Suspense } from 'react';
import { Outlet } from "react-router-dom";

import Loading from 'components/loading/Loading';

const SimpleLayout: FunctionComponent = () =>
    <Suspense fallback={<Loading/>}>
        <main>
            <Outlet />
        </main>
    </Suspense>

export default SimpleLayout;