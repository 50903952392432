import React, { FunctionComponent } from 'react';

import Icon from '../icon/Icon';

import './Button.scss';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    icon?: string,
    iconPosition?: string,
    cssStyle?: string,
    label?: string
}

const Button: FunctionComponent<ButtonProps> = ({ label = "", cssStyle = "", icon = "", iconPosition = "left", children, ...props }) =>
    <button type={props.type || 'button'} onClick={props.onClick} className="adm-button" data-variant={cssStyle} aria-label={label}>
        {icon && iconPosition === "left" ? <Icon name={"/icons.svg#" + icon}></Icon> : null}
        { children }
        {icon && iconPosition === "right" ? <Icon name={"/icons.svg#" + icon}></Icon> : null}
    </button>

export default Button;