/**
 *  ------------------------------------
 *  Imports
 *  ------------------------------------
 */
import { FunctionComponent, useEffect, useRef } from 'react';
import { Navigate } from "react-router-dom";
import i18n from 'i18next';

import { RegistrationDataType } from 'types/RegistrationDataType';
import { FormDataType } from 'types/FormDataType'

import useStorage from 'hooks/useStorage';

import { fetchAPI } from 'services/fetchAPI';
import { parseFormData } from 'services/formData';

import { FormDataProvider } from 'contexts/FormDataContext'

/**
 *  ------------------------------------
 *  RegistrationRoute Component
 *  ------------------------------------
 */
type RegistrationRouteProps = {
    outlet: JSX.Element;
};

const RegistrationRoute: FunctionComponent<RegistrationRouteProps> = ({ outlet }) => {

    const [registrationData] = useStorage('registrationData', {} as RegistrationDataType);
    const [formData, setFormData] = useStorage('formData', {} as FormDataType, {storage: sessionStorage});
    let isLoading = useRef(false);

    useEffect(() => {
        window.dispatchEvent(new CustomEvent("storageChanged", {detail: registrationData}));

        window.addEventListener('languageChange', changeLanguageHandler);
        return () => {
            window.removeEventListener('languageChange', changeLanguageHandler);
        };
        // eslint-disable-next-line
    }, [registrationData])

    useEffect(() => {
        if(!formData.dataLoaded && !isLoading.current){
            isLoading.current = true;
            fetchAPI("/form-data")
                .then((result) => {
                    const data = parseFormData(result);
                    data.dataLoaded = true;
                    data.lang = i18n.language;
                    setFormData(data);
                    isLoading.current = false;
                })
                .catch((err) => { return console.log(err); });
        }  
        // eslint-disable-next-line 
    }, [formData.dataLoaded])

    const changeLanguageHandler = (evt:any) => {
        if(evt.detail !== formData.lang){
            formData.dataLoaded = false;
            formData.lang = evt.detail;
            setFormData(formData);
            window.location.reload();
        }
    }

    return(
        <>
            {registrationData.started
            ?   
                <>
                    {formData.dataLoaded &&
                        <FormDataProvider value={formData}>
                            {outlet}
                        </FormDataProvider>
                        
                    }
                </>
            :
                <Navigate to={{ pathname: '/' }} />
            }
        </>
    )

    /*
        return (
            <>
             { registrationData ?
                
                <>
                    {formData.dataLoaded
                    <FormDataProvider value={formData}>
                        {outlet}
                    </FormDataProvider>
                    }
                </>
            }
            </>
        )
        /*
    } else {
        return <Navigate to={{ pathname: '/' }} />;
    }*/
}

export default RegistrationRoute;