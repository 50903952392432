import i18n from 'i18next';

export async function fetchAPI(path : string, data : any = null, method : string = 'GET') {
    const options : RequestInit = {
        headers: new Headers({'content-type': 'application/json'}),
        method: method
    };

    try {
        if (method === 'POST' && data) {
            options.body = JSON.stringify(data);
        }
        const publicUrl = process.env.REACT_APP_PUBLIC_URL || '';
        const apiSlug = process.env.REACT_APP_API_SLUG || '';
        const currentLanguage = i18n.language;
        const response = await fetch(publicUrl + currentLanguage + apiSlug + path, options);
        let result;
        if (response.ok) {
            result = await response.json();
        } else {
            result = {error: await response.text()};
        }
        return result;
    } catch (err) {
        throw err;
    }
}
