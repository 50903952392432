import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";

import './styles/styles.scss';

import App from './app/App';
import AppLoading from './app/AppLoading';
import { I18nextProvider, i18n } from 'components/i18n/I18n';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<Router>
			<I18nextProvider i18n={i18n}>
				<Suspense fallback={<AppLoading/>}>
					<App />
				</Suspense>
			</I18nextProvider>
		</Router>
	</React.StrictMode>
);
