import { FunctionComponent, lazy } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";

import FormLayout from 'layouts/form/FormLayout';
import SimpleLayout from 'layouts/simple/SimpleLayout';

import RegistrationRoute from 'components/routing/RegistrationRoute';

const StartPage = lazy(() => import('pages/start/StartPage'));
const ImpressumPage = lazy(() => import('pages/impressum/ImpressumPage'));
const PrivacyPage = lazy(() => import('pages/privacy/PrivacyPage'));
const DisclaimerPage = lazy(() => import('pages/disclaimer/DisclaimerPage'));
const Error404Page = lazy(() => import('pages/error/Error404Page'));
const InsuranceCardPage = lazy(() => import('pages/registration/insurance_card/InsuranceCardPage'));
const PersonalDetailsPage = lazy(() => import('pages/registration/personal_details/PersonalDetailsPage'));
const EmployerPage = lazy(() => import('pages/registration/employer/EmployerPage'));
const MedicalAppointmentPage = lazy(() => import('pages/registration/medical_appointment/MedicalAppointmentPage'));
const InsuranceRelationshipPage = lazy(() => import('pages/registration/insurance_relationship/InsuranceRelationshipPage'));
const CheckoutPage = lazy(() => import('pages/registration/checkout/CheckoutPage'));
const CheckoutSuccessPage = lazy(() => import('pages/registration/checkout_success/CheckoutSuccessPage'));

const AppRoutes: FunctionComponent = () =>
    <Routes>
        <Route path="/registration/" element={<FormLayout />}>
            <Route index element={<Navigate to={{ pathname: '/' }} />} />
            <Route path='insurancecard' element={<RegistrationRoute outlet={<InsuranceCardPage />} />} />
            <Route path='personal_details' element={<RegistrationRoute outlet={<PersonalDetailsPage />} />} />
            <Route path='employer' element={<RegistrationRoute outlet={<EmployerPage />} />} />
            <Route path='medical_appointment' element={<RegistrationRoute outlet={<MedicalAppointmentPage />} />} />
            <Route path='insurance_relationship' element={<RegistrationRoute outlet={<InsuranceRelationshipPage />} />} />
            <Route path='checkout' element={<RegistrationRoute outlet={<CheckoutPage />} />} />
            <Route path="*" element={<Error404Page />} />
        </Route>
        <Route path="/" element={<SimpleLayout />}>
            <Route index element={<StartPage />} />
            <Route path="impressum" element={<ImpressumPage />} />
            <Route path="privacy" element={<PrivacyPage />} />
            <Route path="disclaimer" element={<DisclaimerPage />} />
            <Route path='checkout_success' element={<RegistrationRoute outlet={<CheckoutSuccessPage />} />} />
            <Route path="*" element={<Error404Page />} />
        </Route>
        <Route path="/:locale" element={<SimpleLayout />}>
            <Route index element={<StartPage />} />
            <Route path="impressum" element={<ImpressumPage />} />
            <Route path="privacy" element={<PrivacyPage />} />
            <Route path="disclaimer" element={<DisclaimerPage />} />
            <Route path='checkout_success' element={<RegistrationRoute outlet={<CheckoutSuccessPage />} />} />
            <Route path="*" element={<Error404Page />} />
        </Route>
    </Routes>

export default AppRoutes;