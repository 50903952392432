/**
 *  ------------------------------------
 *  Imports
 *  ------------------------------------
 */
import React from 'react'

/**
 *  ------------------------------------
 *  Context
 *  ------------------------------------
 */

const FormDataContext:any = React.createContext({})

/**
 *  ------------------------------------
 *  Exports
 *  ------------------------------------
 */
export const FormDataProvider = FormDataContext.Provider
export default FormDataContext