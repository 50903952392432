/**
 *  ------------------------------------
 *  Imports
 *  ------------------------------------
 */
import { FunctionComponent, useEffect } from 'react';

import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import HelpBox from 'components/helpbox/HelpBox';
import BackToTopButton from 'components/backToTopButton/BackToTopButton';
import AppRoutes from 'app/AppRoutes';
import useStorage from 'hooks/useStorage';

import './App.scss';

/**
 *  ------------------------------------
 *  App Component
 *  ------------------------------------
 */
const App: FunctionComponent = () => {

    const [registrationData, setRegistrationData] = useStorage('registrationData', {});

    useEffect(() => {
        if (registrationData && Object.keys(registrationData).length) {
            // clear stored registration data 
            //setRegistrationData({});  //currently disabled
            setRegistrationData(registrationData);
        }
    }, [registrationData, setRegistrationData]);

    return (
        <div className="app">
            <Header />
            <HelpBox />
            <AppRoutes />
            <Footer />
            <BackToTopButton />
        </div>
    );
}

/**
 *  ------------------------------------
 *  Exports
 *  ------------------------------------
 */
export default App;