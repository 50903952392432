/**
 *  ------------------------------------
 *  Imports
 *  ------------------------------------
 */
import { FunctionComponent } from "react";

import { withTranslation, WithTranslation } from 'react-i18next';

import Navbar from 'components/navbar/Navbar';
import Footermenu from 'components/footer/Footermenu';


import './Mobilemenu.scss'; 

/**
 *  ------------------------------------
 *  HelpBox Component
 *  ------------------------------------
 */
const Mobilemenu: FunctionComponent<WithTranslation> = ({ t }) => {

    return (
        <div className="mobile-menu" id="mobile-menu" aria-labelledby="mobile-menu-trigger">
            <Navbar />
            <div className="mobile-footer-nav">
                <Footermenu></Footermenu>
            </div>
        </div>
    )
}

/**
 *  ------------------------------------
 *  Exports
 *  ------------------------------------
 */
export default withTranslation(['mobilemenu'])(Mobilemenu);