/**
 *  ------------------------------------
 *  Imports
 *  ------------------------------------
 */
import { FunctionComponent, Suspense, useEffect, useState } from 'react';
import { Outlet, useLocation } from "react-router-dom";
import { withTranslation, WithTranslation } from 'react-i18next';

import Loading from 'components/loading/Loading';
import Navbar from 'components/navbar/Navbar';
import { RegistrationDataType } from 'types/RegistrationDataType';

import useStorage from 'hooks/useStorage';

import './FormLayout.scss';

/**
 *  ------------------------------------
 *  FormLayout Component
 *  ------------------------------------
 */
const FormLayout: FunctionComponent<WithTranslation> = ({ t }) => {

    const [name, setName] = useState('');
    const [withIntro, setWithIntro] = useState("");
    const [registrationData] = useStorage('registrationData', {} as RegistrationDataType);

    let location = useLocation();

    useEffect(() => {
        setWithIntro("");
        if(location.pathname === "/registration/insurancecard"){
            setWithIntro("insurancecard");
        }
        if(location.pathname === "/registration/checkout"){
            setWithIntro("checkout");
        }
        
        if(registrationData?.personal_details){
            setName(registrationData.personal_details.firstname + ' ' + registrationData.personal_details.lastname);
        }
    }, [location, registrationData])

    return (
        <Suspense fallback={<Loading />}>
            <main className="form-layout">
                <div className="inner" data-container>
                    {withIntro === 'insurancecard' ? <div className="intro">{t("registration:intro.salutation").replace('@@@name@@@', name)}<br></br>{t("registration:intro.text")}</div> : ''}
                    {withIntro === 'checkout' ? <div className="intro" dangerouslySetInnerHTML={{__html: t("registration:intro.checkout").replace('@@@name@@@', name)}} ></div> : ''}
                    <Navbar />
                    <section>
                        <Outlet />
                    </section>
                </div>
            </main>
        </Suspense>
    );
}

/**
 *  ------------------------------------
 *  Exports
 *  ------------------------------------
 */
export default withTranslation(['registration'])(FormLayout);