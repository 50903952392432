/**
 *  ------------------------------------
 *  Imports
 *  ------------------------------------
 */
import { FunctionComponent, useEffect, useState } from "react";

import { Trans, withTranslation, WithTranslation } from 'react-i18next';

import Icon from '../icon/Icon';

import { RegistrationDataType } from 'types/RegistrationDataType';

import './HelpBox.scss';

/**
 *  ------------------------------------
 *  HelpBox Component
 *  ------------------------------------
 */
const HelpBox: FunctionComponent<WithTranslation> = ({ t }) => {

    const [name, setName] = useState('');
    const [open, setOpen] = useState(false);

    const storageChangedHandler = ((event: CustomEvent) => {
        const registrationData:RegistrationDataType = event.detail;
        const personalDetails = registrationData.personal_details;
        if (personalDetails) {  
            if (personalDetails.firstname && personalDetails.lastname) {
                setName(personalDetails.firstname + ' ' + personalDetails.lastname);
            }
        }
    }) as EventListener;

    useEffect(() => {
        window.addEventListener("storageChanged", storageChangedHandler);
        return () => window.removeEventListener("storageChanged", storageChangedHandler);
    });

    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            if (event.code === "Escape" && open) {
                toggleOpen();
            }
        };
        window.addEventListener('keydown', handleEsc);
        return () => window.removeEventListener('keydown', handleEsc);
    });

    const toggleOpen = () => {
        setOpen(!open);

    }

    return (
        <section className={'help' + ((open) ? ' is-open' : '')}>
            <div className="head">
                <div data-container>
                    <button id="helpbox-trigger" aria-expanded={open} aria-controls="helpbox-body" tabIndex={0} onClick={toggleOpen}>
                        <>{(name) ? (t("helpbox:label") + ' - ' + name + '?') : (t("helpbox:label") + '?')}</>
                        <Icon name={"/icons.svg#chevron-down"}></Icon>
                    </button>
                </div>
            </div>
            <div className="body" id="helpbox-body" aria-labelledby="helpbox-trigger" role="region">
                <div data-container>
                    <div className="box">
                        <div className="contact-option">
                            <a href="mailto:patientenmanagement.fkl@insel.ch"><Icon name={"/icons.svg#mail"}></Icon><Trans i18nKey="helpbox:email"></Trans></a>
                        </div>
                        <div className="contact-option">
                            <a href="tel:+41316643131"><Icon name={"/icons.svg#phone"}></Icon><Trans i18nKey="helpbox:phone"></Trans></a>
                            <p><Trans i18nKey="helpbox:openings"></Trans></p>
                        </div>
                    </div>
                    <button className="adm-button" data-variant="primary" id="helpbox-close" aria-expanded={open} aria-controls="helpbox-body" tabIndex={0} onClick={toggleOpen}>
                        <Icon name={"/icons.svg#close"}></Icon>
                        <Trans i18nKey="helpbox:close"></Trans>
                    </button>
                </div>
            </div>
        </section>
    )
}

/**
 *  ------------------------------------
 *  Exports
 *  ------------------------------------
 */
export default withTranslation(['helpbox'])(HelpBox);
