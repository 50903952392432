import { useEffect, useMemo, useState } from "react";

type Serializer<T> = (object: T | undefined) => string;
type Parser<T> = (val: string) => T | undefined;
type Setter<T> = React.Dispatch<React.SetStateAction<T | undefined>>;

type Options<T> = Partial<{
    serializer: Serializer<T>;
    parser: Parser<T>;
    logger: (error: any) => void;
    storage: Storage;
    syncData: boolean;
}>;

function useStorage<T>(
    key: string,
    defaultValue: T,
    options?: Options<T>
): [T, Setter<T>];
function useStorage<T>(
    key: string,
    defaultValue?: T,
    options?: Options<T>
) {
    const opts = useMemo(() => {
        return {
            serializer: JSON.stringify,
            parser: JSON.parse,
            logger: console.log,
            storage: (process.env.NODE_ENV === "development") ? localStorage : sessionStorage,
            syncData: false,
            ...options,
        };
    }, [options]);

    const { serializer, parser, logger, storage, syncData } = opts;

    const [storedValue, setValue] = useState(() => {
        try {
            const item = storage.getItem(key);
            const res: T = item ? parser(item) : defaultValue;
            return res;
        } catch (e) {
            logger(e);
            return defaultValue;
        }
    });

    const setItem = (newValue: T) => {
        setValue(newValue);
        storage.setItem(key, serializer(newValue));
    };

    useEffect(() => {
        const newValue = storage.getItem(key);
        if (serializer(storedValue) !== newValue) {
            const parsedValue = (newValue) ? parser(newValue) : defaultValue;
            setItem(parsedValue);
        }
    });

    useEffect(() => {
        if (!syncData) return;

        const handleStorageChange = (e: StorageEvent) => {
            if (e.key !== key || e.storageArea !== storage) return;

            try {
                setValue(e.newValue ? parser(e.newValue) : undefined);
            } catch (e) {
                logger(e);
            }
        };

        window.addEventListener("storage", handleStorageChange);
        return () => window.removeEventListener("storage", handleStorageChange);
    });

    return [storedValue, setItem];
}

export default useStorage;